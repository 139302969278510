
import { Component, Inject, Vue } from "vue-property-decorator";
import { API_ENDPOINT_SYMBOL } from "@/constants";

@Component({})
export default class AdminLogin extends Vue {
  @Inject(API_ENDPOINT_SYMBOL) apiEndPoint;
  username = "";
  password = "";
  code = "";
  public selectedServer: string | undefined = "BUY";

  created() {
    this.selectedServer = this.$q.cookies.get("selected-server") || "BUY";
  }

  onSelectedServerChanged(value) {
    if (value === "BUY") {
      this.$q.cookies.remove("apollo-ws-endpoint", {path: '/'});
      this.$q.cookies.remove("apollo-http-endpoint", {path: '/'});
      this.$q.cookies.remove("http-endpoint", {path: '/'});
      this.$q.cookies.remove("selected-server", {path: '/'});
    } else if (value === "SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://api-sell.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://api-sell.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set("http-endpoint", "https://api-sell.skins.plus/", {
        expires: 365,
        path: '/'
      });
      this.$q.cookies.set("selected-server", "SELL", { expires: 365,
        path: '/' });
    }
    else if (value === "BS-SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://buyskins.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://buyskins.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set("http-endpoint", "https://buyskins.skins.plus/", {
        expires: 365,
        path: '/'
      });
      this.$q.cookies.set("selected-server", "BS-SELL", { expires: 365,
        path: '/' });
    }else if (value === "TEMP-SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://temp.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://temp.skins.plus/graphql",
        { expires: 365,
          path: '/' }
      );
      this.$q.cookies.set("http-endpoint", "https://temp.skins.plus/", {
        expires: 365,
        path: '/'
      });
      this.$q.cookies.set("selected-server", "TEMP-SELL", { expires: 365,
        path: '/' });
    }
    window.location.reload();
  }
}

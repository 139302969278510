
import {
  Component,
  InjectReactive,
  Provide,
  ProvideReactive,
  Vue
} from "vue-property-decorator";
import getCookie from "@/get-cookie";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import AdminLogin from "@/components/admin/AdminLogin.vue";
import {
  ADMIN_PROFILE_SYMBOL,
  API_ENDPOINT_SYMBOL,
  BALANCE_CURRENCY_SYMBOL,
  BALANCE_RATES_SYMBOL,
  SELL_SERVER_URL_SYMBOL
} from "@/constants";
import gql from "graphql-tag";

class DrawerLink {
  constructor(
    public label: string,
    public icon: string,
    public link: string,
    public hidden: boolean = false
  ) {}
}

@Component({
  components: {
    CircleLoading,
    AdminLogin
  }
})
export default class AdminLayout extends Vue {
  public selectedServer: string | undefined = "BUY";
  @Provide(API_ENDPOINT_SYMBOL) public apiEndPoint: string =
    getCookie("http-endpoint") || process.env.VUE_APP_API_ENDPOINT;
  @ProvideReactive(SELL_SERVER_URL_SYMBOL) public sellServerUrl: string =
    process.env.VUE_APP_SELL_SERVER_URL;
  @ProvideReactive(ADMIN_PROFILE_SYMBOL) public admin: any | null = null;

  drawer = false;
  drawerLinks: DrawerLink[] = [
    new DrawerLink(
      "Боты",
      "fas fa-robot",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot"
    ),
    new DrawerLink(
      "Прокси",
      "fas fa-wifi",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot-proxy"
    ),
    new DrawerLink(
      "Пользователи",
      "fas fa-users",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/users"
    ),
    new DrawerLink(
      "Скины",
      "fas fa-money-bill-alt",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-item"
    ),
    new DrawerLink(
      "Правила цен скинов",
      "fas fa-funnel-dollar",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-item-price-rule"
    ),
    new DrawerLink(
      "Офферы",
      "fas fa-handshake",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot-offer"
    ),
    new DrawerLink(
      "Скины ботов",
      "fab fa-android",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot-item"
    ),
    new DrawerLink(
      "Маркет CSGO",
      "fas fa-shopping-cart",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/market-csgo-item"
    ),
    new DrawerLink(
      "Депозиты",
      "fas fa-plus-square",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/user-balance-deposit"
    ),
    new DrawerLink(
      "Выводы",
      "fas fa-minus-square",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/user-balance-withdraw"
    ),
    new DrawerLink(
      "Уведомления",
      "fas fa-bell",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/firebase-token"
    ),
    new DrawerLink(
      "Правила кол-во предметов",
      "fas fa-chart-bar",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/items-counter-rule"
    ),
    new DrawerLink(
      "Кол-во предметов",
      "fas fa-chart-pie",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/items-counter"
    ),
    new DrawerLink(
      "Промокоды",
      "fas fa-percentage",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/promocodes"
    ),
    new DrawerLink(
      "Настройки",
      "fas fa-cog",
      "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/settings"
    )
  ];

  created() {
    this.selectedServer = this.$q.cookies.get("selected-server") || "BUY";

    if (this.selectedServer === "BS-SELL") {
      this.sellServerUrl = process.env.VUE_APP_BS_SELL_SERVER_URL;
    } else if (this.selectedServer === "TEMP-SELL") {
      this.sellServerUrl = process.env.VUE_APP_TEMP_SELL_SERVER_URL;
    }
  }

  onSelectedServerChanged(value) {
    if (value === "BUY") {
      this.$q.cookies.remove("apollo-ws-endpoint", { path: "/" });
      this.$q.cookies.remove("apollo-http-endpoint", { path: "/" });
      this.$q.cookies.remove("http-endpoint", { path: "/" });
      this.$q.cookies.remove("selected-server", { path: "/" });
    } else if (value === "SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://api-sell.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://api-sell.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set("http-endpoint", "https://api-sell.skins.plus/", {
        expires: 365,
        path: "/"
      });
      this.$q.cookies.set("selected-server", "SELL", {
        expires: 365,
        path: "/"
      });
    } else if (value === "BS-SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://buyskins.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://buyskins.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set("http-endpoint", "https://buyskins.skins.plus/", {
        expires: 365,
        path: "/"
      });
      this.$q.cookies.set("selected-server", "BS-SELL", {
        expires: 365,
        path: "/"
      });
    } else if (value === "TEMP-SELL") {
      this.$q.cookies.set(
        "apollo-ws-endpoint",
        "wss://temp.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set(
        "apollo-http-endpoint",
        "https://temp.skins.plus/graphql",
        { expires: 365, path: "/" }
      );
      this.$q.cookies.set("http-endpoint", "https://temp.skins.plus/", {
        expires: 365,
        path: "/"
      });
      this.$q.cookies.set("selected-server", "TEMP-SELL", {
        expires: 365,
        path: "/"
      });
    }

    window.location.reload();
  }

  getAdminProfileResult({ data }) {
    if (data && data.getAdminProfile) {
      this.admin = data.getAdminProfile;

      if (this.admin.role !== "ADMIN") {
        [
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot-item",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-item-price-rule",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-bot-offer",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/steam-item",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/users",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/user-balance-deposit",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/user-balance-withdraw",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/firebase-token",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/items-counter",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/items-counter-rule",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/promocodes",
          "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/settings"
        ].forEach(link => {
          const drawerLink = this.drawerLinks.find(
            drawerLink => drawerLink.link === link
          );
          if (drawerLink) {
            drawerLink.hidden = true;
          }
        });
      }
    }
  }
}

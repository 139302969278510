var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{attrs:{"id":"admin-layout","query":gql => gql`
      query getAdminProfile {
        getAdminProfile {
          _id
          role
          username
        }
      }
    `},on:{"result":_vm.getAdminProfileResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data, loading, error } }){return [_c('fade-transition',{attrs:{"mode":"out-in","duration":150}},[(!data && !error)?_c('CircleLoading',{key:'admin-layout',staticClass:"absolute absolute-center",attrs:{"size":"5rem"}}):(data && !error)?_c('q-layout',{key:'admin-layout',staticClass:"window-width",attrs:{"view":"hHr lpR fFr"}},[_c('q-header',{staticClass:"no-shadow bg-primary"},[_c('q-toolbar',[_c('q-btn',{attrs:{"flat":"","round":"","size":"sm","icon":"icon-i-app-bars"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('q-toolbar-title',{staticClass:"text-weight-medium ellipsis q-ml-md"},[_c('router-link',{staticClass:"cursor-pointer",attrs:{"tag":"span","to":"/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2"}},[_vm._v("SKINSPLUS")])],1),_c('q-select',{staticClass:"q-mr-sm",staticStyle:{"min-width":"120px"},attrs:{"options":['BUY', 'SELL', 'BS-SELL', 'TEMP-SELL'],"label":"сервер","outlined":"","dense":"","rounded":"","bg-color":"white"},on:{"input":_vm.onSelectedServerChanged},model:{value:(_vm.selectedServer),callback:function ($$v) {_vm.selectedServer=$$v},expression:"selectedServer"}}),_c('span',{staticClass:"text-weight-bold text-subtitle1"},[_vm._v(" "+_vm._s(_vm.admin.username))]),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"type":"a","href":_vm.apiEndPoint +
                  'auth/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2/logout',"icon":"fas fa-sign-out-alt","size":"sm","flat":"","round":""}})],1)],1),_c('q-drawer',{attrs:{"show-if-above":"","width":200,"breakpoint":500,"content-style":"background: #050A12"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('q-scroll-area',{staticClass:"fit"},[_c('q-list',{attrs:{"padding":""}},_vm._l((_vm.drawerLinks),function(drawerLink,i){return (!drawerLink.hidden)?_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,attrs:{"dark":"","clickable":"","exact":"","to":drawerLink.link}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":drawerLink.icon}})],1),_c('q-item-section',[_vm._v(" "+_vm._s(drawerLink.label)+" ")])],1):_vm._e()}),1)],1)],1),_c('q-page-container',[_c('fade-transition',{attrs:{"duration":300,"mode":"out-in"}},[_c('router-view')],1)],1)],1):_c('AdminLogin')],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }